import React from 'react';

import './payment-page.styles.scss';

const PaymentPage = () => {
    return (
        <div className="payment-page-container container">
            <div className="">
                <div className="">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6">
                            <address>
                                <strong style={{fontSize: "150%"}}>Invenus </strong>
                            </address>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 text-right">
                            <p>
                                <em>Date: 28th May 2022</em>
                            </p>
                            <p>
                                <em>Confirmation Number #: 34522677W (This will be generated by backend ig)</em>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="text-center">
                            <h1>Payment Confirmation</h1>
                        </div>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th className="text-center">Amount</th>
                                <th className="text-center">PPT</th>
                                <th className="text-center">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="col-md-9"><em>Neuraum ticket</em></td>
                                <td className="col-md-1" style={{textAlign: "center"}}> 4</td>
                                <td className="col-md-1 text-center"><span>&#8364;</span>13</td>
                                <td className="col-md-1 text-center"><span>&#8364;</span>52</td>
                            </tr>
                            </tbody>
                        </table>
                        <button type="button" className="payment-page-payment-button btn"> Pay Now <span
                            className="glyphicon glyphicon-chevron-right"> </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentPage;