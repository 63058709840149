import React from 'react';
import './payment-success-page.styles.scss';

const PaymentSuccessPage = () => {
    return (
        <div>
            <h1>
                Thank you for your purchase.
            </h1>
        </div>
    )
}

export default PaymentSuccessPage;